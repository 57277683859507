import axios from '@/utils/axios';
const apiUrl = {
  //主页的接口
  pageBusinessWaringData: '/business_waring/page',
  businessWaringStatisticsData: '/business_waring/statistics',
  exportExcel: '/extractor/tt/adv/log/export',
};

//查询接口
export function pageBusinessWaringDataAPI(params) {
  return axios.post(apiUrl.pageBusinessWaringData, params);
}

export function businessWaringStatisticsDataAPI(params) {
  return axios.post(apiUrl.businessWaringStatisticsData, params);
}


export function exportExcel(param) {
  return axios.post(apiUrl.exportExcel, param, {
    timeout: 30 * 1000,
    withCredentials: true,
    responseType: 'blob',
  });
}