<template>
  <div class="media w100 h100">
    <div class="filter_row">
      <a-tabs v-model:activeKey="activeTabKey" @change="onTabChange">
        <a-tab-pane key="TT" tab="巨量引擎" force-render></a-tab-pane>
             <a-tab-pane key="GDT" tab="腾讯广告"></a-tab-pane>
        <!--  <a-tab-pane key="KS" tab="磁力引擎"></a-tab-pane>
       <a-tab-pane key="QC" tab="巨量千川"></a-tab-pane>-->
      </a-tabs>
      <a-form :model="filterConditions">
        <a-row type="flex">
          <div style="flex: 1">
            <a-row class="media-choice">
              <a-col :span="4">
                <a-form-item label='产品名称' :label-col="{ span: 0 }" :wrapper-col="{ span: 17 }">
                  <a-input v-model:value="filterConditions.projectName" placeholder="请输入产品名称"></a-input>
                </a-form-item>
              </a-col>

              <a-col :span="6">
                <a-form-item label='预警类型' :label-col="{ span: 0 }" :wrapper-col="{ span: 14 }">
                  <a-select mode="multiple" v-model:value="filterConditions.msgTypes" placeholder="请选择预警类型" allow-clear style="width: 100%">
                    <a-select-option value="SOP数据异常">SOP数据异常</a-select-option>
                    <a-select-option value="基础数据丢失">基础数据丢失</a-select-option>
                    <a-select-option value="优化目标预警">优化目标预警</a-select-option>
                    <a-select-option value="深度优化目标预警">深度优化目标预警</a-select-option>
                    <a-select-option value="项目出价预警">项目出价预警</a-select-option>
                    <a-select-option value="广告出价预警">广告出价预警</a-select-option>
                    <a-select-option value="广告深度出价预警">广告深度出价预警</a-select-option>
                    <a-select-option value="项目深度转化出价预警">项目深度转化出价预警</a-select-option>
                    <a-select-option value="ROI出价系数预警">ROI出价系数预警</a-select-option>

                    <a-select-option value="广告文案预警">广告文案预警</a-select-option>
                    <a-select-option value="素材名称预警">素材名称预警</a-select-option>

                    <a-select-option value="项目定向包预警">项目定向包预警</a-select-option>

                    <a-select-option value="时段设置SOP填写错误预警">时段设置SOP填写错误预警</a-select-option>
                    <a-select-option value="时段设置预警">时段设置预警</a-select-option>

                    <a-select-option value="项目监测链接预警">项目监测链接预警</a-select-option>

                    <a-select-option value="投放模式异常预警">投放模式异常预警</a-select-option>
                    <a-select-option value="版位选择异常预警">版位选择异常预警</a-select-option>

                    <a-select-option value="RTA重定向异常预警">RTA重定向异常预警</a-select-option>

                    <a-select-option value="落地页异常预警">落地页异常预警</a-select-option>
                    <a-select-option value="搜索快投异常预警">搜索快投异常预警</a-select-option>
                    <a-select-option value="行动号召文案异常预警">行动号召文案异常预警</a-select-option>
                    <a-select-option value="广告原生可见情况异常预警">广告原生可见情况异常预警</a-select-option>
                    <a-select-option value="抖音号选择异常预警">抖音号选择异常预警</a-select-option>
                    <a-select-option value="项目下载链接异常预警">项目下载链接异常预警</a-select-option>
                    <a-select-option value="批量关停记录">批量关停记录</a-select-option>
                    <a-select-option value="锚点异常预警">锚点异常预警</a-select-option>
                    <a-select-option value="金融落地页异常预警">金融落地页异常预警</a-select-option>

                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="4">
                <a-form-item label='账户ID' :label-col="{ span: 0 }" :wrapper-col="{ span: 14 }">
                  <a-input v-model:value="filterConditions.advertiserIdsStr" placeholder="批量查询ID请用空格隔开"></a-input>
                </a-form-item>
              </a-col>
              <a-col :span="4">
                <a-form-item label='预警目标' :label-col="{ span: 0 }" :wrapper-col="{ span: 14 }">
                  <a-select v-model:value="filterConditions.msgTarget" placeholder="请选择预警目标" allow-clear style="width: 100%">
                    <a-select-option value="账号">账号</a-select-option>
                    <a-select-option value="项目">项目</a-select-option>
                    <a-select-option value="广告">广告</a-select-option>
                    <a-select-option value="素材">素材</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>

              <a-col :span="6">
                <a-form-item label='预警时间' :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
                  <a-range-picker :placeholder="['开始日期', '结束日期']" v-model:value="defaultRange" :allow-clear="false" separator="到" :format="[dateRangeFormat, dateRangeFormat]" :ranges="dateRanges" :disabled-date="disabledDate" @change="onDateRangeChange" :disabled="tableLoading" />
                </a-form-item>
              </a-col>
            </a-row>
          </div>
          <a-space>
            <a-button type="primary" @click="onSearchBtnClick" :disabled="tableLoading">筛选</a-button>
            <a-button @click="resetFilter" :disabled="tableLoading">重置</a-button>
            <a-button v-show="false" :loading="excelExporting" :disabled="tableLoading" @click="onExportExcelBtnClick">导出Excel</a-button>
          </a-space>
        </a-row>
      </a-form>
    </div>
    <div class="table_wrapper">
      <a-space :size="10" class="table_operation">

      </a-space>
      <a-row>
        <a-col :span="6" v-for="item in businessWaringStatisticsData" :key="item.msgType" >
          <a-descriptions :title="item.msgType" size="small" >
            <a-descriptions-item label="预警账户数">{{item.numOfAccount}}</a-descriptions-item>
            <a-descriptions-item label="预警目标数">{{item.numOfTargetIds}}</a-descriptions-item>
            <a-descriptions-item label="预警记录数">{{item.numOfRecords}}</a-descriptions-item>
          </a-descriptions>
        </a-col>
      </a-row>

      <a-table :columns="tableColumns" :data-source="tableData" class="w100" size="small" bordered :loading="tableLoading" :pagination="tablePagination" @change="onTableChange">
          <template #hasProcessedSlot="{ record }">
            {{ record.hasProcessed === 'Y' ? '程序已自动修复':'请人工手动处理' }}
          </template>
          <template #msgTextSlot="{ record }">
            <span >{{record.msgText}}</span>
          </template>
          <template #expandedRowRender="{ record }">
            <p style="margin: 0">
              <a-textarea class="non-editable"  v-model:value="record.msgText "  />
            </p>
          </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import moment, { Moment } from 'moment';
import locale from 'ant-design-vue/lib/locale-provider/zh_CN';
import {
  getManagerAccountListData,
} from '@/api/mediaAccount/mediaAccount.js';
import { Empty, message } from 'ant-design-vue';
import { mapState } from 'vuex';
import { ref } from 'vue';
import { pageBusinessWaringDataAPI,exportExcel,businessWaringStatisticsDataAPI } from '@/api/tools/businessWaringAPI';
export default {
  data() {
    // 从推广管理账户批量分配账号带过来的数据
    const activeTabKey = this.$route?.params?.channel || 'TT';
    const dateRangeFormat = 'YYYY-MM-DD';
    const dateRanges = {
      昨天: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      最近三天: [moment().subtract(2, 'days'), moment()],
      本周: [moment().startOf('week'), moment.min(moment(), moment().endOf('week'))],
      最近七天: [moment().subtract(6, 'days'), moment()],
      本月: [moment().startOf('month'), moment.min(moment(), moment().endOf('month'))],
      最近三十天: [moment().subtract(29, 'days'), moment()],
      最近九十天: [moment().subtract(89, 'days'), moment()],
    };
    const disabledDate = current => {
      return current && current > moment().add(1, 'days').startOf('day');
    };
    // rangepicker默认选中日期
    const defaultRange = null ;
    const excelExporting = ref(false);
    return {
      businessWaringStatisticsData: [
      ],
      foldFilter: true, //是否折叠筛选区
      moment,
      dateRangeFormat,
      dateRanges,
      disabledDate,
      defaultRange,
      excelExporting,
      activeTabKey: activeTabKey,
      locale,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      distributionOperatorDisabled: true, //会话框运营人员禁用
      tableLoading: false,
      selectedRowKeys: [], // 选中的行的id
      selectedRows: [],
      tablePagination: {
        limit: '30',
        page: '1',
        pageSize: 30,
        showTotal: total => `总计${total}条数据`,
        showSizeChanger: true,
        pageSizeOptions: ['30', '50', '100'],
      },
      tableColumns: [
        {
          title: '产品名称',
          width: 100,
          dataIndex: 'projectName',
          key: 'projectName',
        },
        {
          title: '消息类型',
          width: 130,
          dataIndex: 'msgType',
          key: 'msgType',
        },
        {
          title: '广告主ID',
          width: 100,
          key: 'advertiserId',
          dataIndex: 'advertiserId',
        },
        {
          title: '预警层级',
          width: 100,
          dataIndex: 'msgTarget',
          key: 'msgTarget',
        },
        {
          title: '预警目标ID',
          width: 100,
          dataIndex: 'targetId',
          key: 'targetId',
        },
        {
          title: '预警消息内容',
          width: 450,
          key: 'msgText',
          dataIndex: 'msgText',
          slots: {
            customRender: 'msgTextSlot',
          },
        },
        {
          title: '自动处理情况',
          width: 150,
          key: 'hasProcessed',
          dataIndex: 'hasProcessed',
          slots: {
            customRender: 'hasProcessedSlot',
          },
        },
        {
          title: '备注',
          width: 150,
          key: 'remarks',
          dataIndex: 'remarks',
        },
        {
          title: '预警时间',
          width: 150,
          key: 'createTime',
          dataIndex: 'createTime',
        },
      ],
      tableData: [],
      managerAccountListTemp:[],
      filterConditions: {
        projectName:'',
        msgTypes:[],
        msgTypesStr:'',
        advertiserIds:[],
        advertiserIdsStr:'',
        msgTarget:'',
        // startDate: moment().startOf('month').format(dateRangeFormat),
        // endDate:moment().format(dateRangeFormat),
      },
      labelCol: { sm: { span: 8 }, md: { span: 8 }, lg: { span: 8 }, xl: { span: 8 }, xxl: { span: 6 } },
      wrapperCol: { sm: { span: 14 }, md: { span: 14 }, lg: { span: 14 }, xl: { span: 14 }, xxl: { span: 16 } },
      rowData: {}, //当前行的数据
      filterConditionMap: {},
    };
  },
  inject: ['$bus'],
  computed: {
    periodComputed(){
      return  moment(new Date()).format("YYYY-MM") ;
    },
    ...mapState({ userJobs: state => state.oauth.userInfo.jobs }),
    funcPoints() {
      return localStorage.getItem('funcPoint');
    },
  },
  created() {
    this.getManagerAccountListTemp();
    this.pageBusinessWaring();
  },
  methods: {
    getManagerAccountListTemp(){
      let mediaChannelTemp = this.activeTabKey;
      getManagerAccountListData(mediaChannelTemp).then(res => {
        if (res.code == 0) {
          this.managerAccountListTemp  = res.data;
        }
      });
    },
    // 开始时间结束时间选择
    onDateRangeChange(val, dateString) {
      this.filterConditions.startDate = dateString[0];
      this.filterConditions.endDate = dateString[1];
      this.tablePagination.page = '1';
      this.pageBusinessWaring();
    },
    onTabChange() {
      this.selectedRowKeys = [];
      this.selectedRows = [];
      this.resetFilter();
    },
    pageBusinessWaring() {
      if (this.tableLoading === true) {
        return;
      }
      this.tableLoading = true;
      if(this.filterConditions.projectName){
        this.filterConditions.projectName = this.filterConditions.projectName.trim();
      }
      this.filterConditions.advertiserIds = [];
      if(this.filterConditions.advertiserIdsStr && this.filterConditions.advertiserIdsStr.trim().indexOf(" ") > 0){
        this.filterConditions.advertiserIds = this.filterConditions.advertiserIdsStr.trim().split(' ');
      }else if(this.filterConditions.advertiserIdsStr && this.filterConditions.advertiserIdsStr.trim().indexOf(",") > 0){
        this.filterConditions.advertiserIds = this.filterConditions.advertiserIdsStr.trim().split(',');
      }else if(this.filterConditions.advertiserIdsStr){
        this.filterConditions.advertiserIds = this.filterConditions.advertiserIdsStr.trim().split(' ');
      }
      let params = {
        mediaChannel: this.activeTabKey === 'TT' ? '头条':'腾讯',
        startDate: this.filterConditions.startDate,
        endDate: this.filterConditions.endDate,
        ...this.tablePagination,
        ...this.filterConditions,
      };
      pageBusinessWaringDataAPI(params).then(res => {
        if (res.code == 0) {
          this.tableData = res.page.list;
          this.tablePagination.current = res.page.currPage;
          this.tablePagination.pageSize = res.page.pageSize;
          this.tablePagination.total = res.page.totalCount;
        }
      }).catch(error => {
        message.error(error.message,3)
      }).finally(() => {
        this.tableLoading = false;
      })

      businessWaringStatisticsDataAPI(params).then(res => {
        if (res.code == 0) {
          this.businessWaringStatisticsData = res.data;
        }
      }).catch(error => {
        message.error(error.message,3)
      }).finally(() => {
        this.tableLoading = false;
      })

    },
    // 表格翻页
    onTableChange(pagination) {
      this.selectedRowKeys = [];
      this.selectedRows = [];
      this.tablePagination.page = pagination.current.toString();
      this.tablePagination.limit = pagination.pageSize.toString();
      this.tablePagination.pageSize = pagination.pageSize;
      this.pageBusinessWaring();
    },
    async onExportExcelBtnClick() {
      this.excelExporting = true;
      if(this.filterConditions.projectName){
        this.filterConditions.projectName = this.filterConditions.projectName.trim();
      }
      this.filterConditions.advertiserIds = [];
      if(this.filterConditions.advertiserIdsStr){
        this.filterConditions.advertiserIds = this.filterConditions.advertiserIdsStr.trim().split(' ');
      }

      let params = {
        mediaChannel: this.activeTabKey,
        startDate: this.filterConditions.startDate,
        endDate: this.filterConditions.endDate,
        ...this.tablePagination,
        ...this.filterConditions,
      };
      exportExcel(params).then(res => {
        let blob = new Blob([res], { type: 'application/vnd.ms-excel' });
        let objectUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = objectUrl;
        link.download = `预警信息详情.xlsx`;
        link.click();
        URL.revokeObjectURL(objectUrl);
      }).catch(error => {
        message.error(error.message,3)
      }).finally(() => {
        this.excelExporting = false;
      })
    },
    onSearchBtnClick() {
      this.selectedRowKeys = [];
      this.selectedRows = [];
      if (this.tablePagination.page !== '1') {
        this.tablePagination = {};
        this.tablePagination.limit = '10';
        this.tablePagination.page = '1';
      }
      this.pageBusinessWaring();
    },
    resetFilter() {
      this.selectedRowKeys = [];
      this.selectedRows = [];
      this.tablePagination.page = '1';
      this.filterConditions = {
        period: moment(new Date()).format("YYYY-MM") ,//素材清理月份
      },
        this.pageBusinessWaring();
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
  },
};
</script>

<style lang="less">
@import url('./index.less');
</style>
<style lang="less" scoped>
.media {
  display: flex;
  flex-direction: column;
  :deep(.filter_row) {
    padding-top: 0;
    padding-bottom: 6px;
    .ant-tabs-top-bar {
      margin-bottom: 8px;
    }
  }
  .table_wrapper {
    display: flex;
    flex-direction: column;
    .table_operation {
      margin-bottom: 10px;
    }
    :deep(.media_account_table) {
      flex-grow: 1;
      .ant-table-scroll .ant-table-body {
        max-height: calc(100vh - 390px) !important;
      }
      .ant-table-pagination {
        margin-bottom: 0 !important;
        margin-top: 12px;
      }
    }
  }
  .non-editable {
    color: inherit; /* 保持文本颜色不变 */
    pointer-events: none; /* 阻止用户交互 */
    background-color: #ffffff; /* 可选：设置背景色为灰色或其他颜色以表示禁用状态 */
    height: 400px;
    color: #da3232;
  }

}
</style>